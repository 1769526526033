/*------ 17. Breadcrumb style  --------*/

.breadcrumb-area {
	padding: 45px 0;
}
.breadcrumb-content {
    ul{
        li {
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: $theme-color-red;
            margin-right: 18px;
            text-transform: capitalize;
            position: relative;
            &::before {
                position: absolute;
                width: 18px;
                height: 1px;
                background-color: #5b5858;
                content: "";
                right: -21px;
                top: 12px;
                z-index: 9;
                transform: rotate(115deg);
            }
            &:last-child::before {
                display: none;
            }
            a {
                color: $black;
                font-weight: 600;
                &:hover {
                    color: $theme-color-red;
                }
            }
        }
    }
}



