/*-------- 27. Order tracking style ---------*/

.order-tracking-content {
    p {
        line-height: 30px;
        margin: 0 auto;
        text-align: center;
        width: 89%;
        color: #333;
        @media #{$xs-layout} {
            width: 100%;
        }
    }
    .order-tracking-form {
        margin: 30px 0 0;
        .sin-order-tracking {
            margin: 0 0 36px;
            label {
                letter-spacing: .2px;
                font-weight: 600;
                margin: 0 0 10px;
                display: block;
            }
            input {
                background: transparent;
                border: 1px solid #cecece;
                height: 60px;
                padding: 10px 20px;
                color: $black;
                font-size: 14px;
                &:focus {
                    border: 1px solid #262626;
                }
            }
        }
        .order-track-btn {
            text-align: center;
            a {
                display: block;
                font-weight: 600;
                color: $white;
                background-color: $black;
                line-height: 1;
                padding: 22px 45px;
                text-transform: uppercase;
                &:hover {
                    background-color: $theme-color-red;
                }
            }
        }
    }
}



