/*-------- 4. Slider style ---------*/

.slider-height-1 {
    height: 585px;
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: 440px;
    }
}
.slider-height-2 {
    height: 710px;
    @media #{$xx-layout} {
        height: 600px;
    }
    @media #{$xl-layout} {
        height: 600px;
    }
    @media #{$lg-layout} {
        height: 550px;
    }
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: 400px;
    }
}
.slider-height-3 {
    height: 350px;
    @media #{$xs-layout} {
        height: auto;
        padding: 50px 0;
    }
}

.slider-height-4 {
    height: 520px;
    @media #{$lg-layout} {
        height: 475px;
    }
    @media #{$md-layout} {
        height: 475px;
    }
    @media #{$xs-layout} {
        height: 450px;
    }
}

.hero-slider-pt-1 {
    padding-top: 150px;
    @media #{$md-layout} {
        padding-top: 100px;
    }
    @media #{$xs-layout} {
        padding-top: 110px;
    }
    @media #{$sm-layout} {
        padding-top: 90px;
    }
}

.hero-slider-content-1-pt-1 {
    padding-top: 125px;
    @media #{$md-layout} {
        padding-top: 70px;
    }
    @media #{$xs-layout} {
        padding-top: 0px;
    }
    @media #{$sm-layout} {
        padding-top: 40px;
    }
}

.hero-slider-content-1 {
    h4 {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 0;
        &.font-dec {
            font-size: 16px;
        }
    }
    h1 {
        font-size: 72px;
        line-height: 72px;
        font-weight: bold;
        margin: 20px 0 42px;
        font-family: $heebo;
        letter-spacing: -.25px;
        &.font-dec {
            font-size: 39px;
            line-height: 48px;
            font-family: $poppins;
            margin: 16px 0 28px;
            @media #{$lg-layout} {
                font-size: 30px;
                line-height: 40px;
                margin: 6px 0 10px;
            }
            @media #{$md-layout} {
                font-size: 35px;
                line-height: 45px;
                margin: 12px 0 15px;
            }
            @media #{$xs-layout} {
                font-size: 28px;
                line-height: 40px;
                margin: 10px 0 10px;
            }
            @media #{$sm-layout} {
                font-size: 24px;
                line-height: 32px;
                margin: 5px 0 5px;
            }
        }
        @media #{$lg-layout} {
            font-size: 55px;
            line-height: 65px;
            margin: 12px 0 20px;
        }
        @media #{$md-layout} {
            font-size: 45px;
            line-height: 56px;
            margin: 10px 0 15px;
        }
        @media #{$xs-layout} {
            font-size: 32px;
            line-height: 45px;
            margin: 12px 0 12px;
        }
        @media #{$sm-layout} {
            font-size: 30px;
            line-height: 35px;
            margin: 5px 0 10px;
        }
    }
    p {
        font-size: 16px;
        line-height: 30px;
        margin: 0px 0 80px;
        width: 78%;
        &.width-inc {
            width: 96%;
            margin: 0px 0 36px;
            @media #{$lg-layout} {
                width: 100%;
                margin: 0px 0 14px;
            }
            @media #{$md-layout} {
                margin: 0px 0 16px;
            }
            @media #{$xs-layout} {
                margin: 0px 0 16px;
            }
            @media #{$sm-layout} {
                width: 100%;
                line-height: 25px;
            }
        }
        @media #{$lg-layout} {
            margin: 0px 0 30px;
        }
        @media #{$md-layout} {
            font-size: 15px;
            margin: 0px 0 30px;
            width: 100%;
        }
        @media #{$xs-layout} {
            margin: 0px 0 20px;
            width: 98%;
        }
        @media #{$sm-layout} {
            font-size: 15px;
            line-height: 28px;
            margin: 0px 0 15px;
        }
    }
    &.hero-slider-content-1-padding1 {
        padding: 63px 0 0 70px;
        @media #{$lg-layout} {
            padding: 40px 0 0 15px;
        }
        @media #{$md-layout} {
            padding: 63px 0 0 20px;
        }
        @media #{$xs-layout} {
            padding: 50px 15px 0 15px;
            text-align: center;
        }
        @media #{$sm-layout} {
            padding: 50px 0px 0 15px;
            text-align: left;
        }
    }
}

.btn-style-1 {
    a {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        background-color: $black;
        color: $white;
        &:hover {
            background-color: $theme-color-red;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            font-weight: 500;
        }
        &.btn-1-padding-1 {
            padding: 11px 26px 15px;
            @media #{$xs-layout} {
                padding: 8px 20px 12px;
            }
        }
        &.btn-1-bg-purple {
            background-color: $theme-color-purple;
        }
        &.btn-1-padding-2 {
            padding: 8px 22px 12px;
        }
        &.btn-1-padding-3 {
            padding: 12px 25px 14px;
            @media #{$xs-layout} {
                &.banner-btn-res {
                    padding: 5px 15px 5px;
                    font-size: 13px;
                }
            }
        }
        &.btn-1-padding-4 {
            padding: 8px 20px 13px;
            @media #{$md-layout} {
                padding: 6px 15px 10px;
            }
            @media #{$xs-layout} {
                font-size: 13px;
                padding: 2px 12px 5px;
            }
        }
        &.bg-white {
            background-color: $white;
            color: $black;
            &:hover {
                background-color: $bg-black !important;
                color: $white;
            }
        }
        &.btn-1-green-2 {
            background-color: $theme-color-green-2;
            &:hover {
                background-color: $bg-black;
                color: $white;
            }
        }
        &.btn-1-blue {
            background-color: $theme-color-blue;
            &:hover {
                background-color: $bg-black;
                color: $white;
            }
        }
        &.btn-1-orange {
            background-color: $orange;
            &:hover {
                background-color: $bg-black;
                color: $white;
            }
        }
        &.btn-1-font-14 {
            font-size: 14px;
        }
    }
}

.hero-slider-img-1 {
    margin-right: -80px;
    margin-left: 80px;
    @media #{$xx-layout} {
        margin-right: 0px;
        margin-left: 0px;
    }
    @media #{$xl-layout} {
        margin-right: 0px;
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 30px;
    }
    img {
        width: 100%;
    }
}

.hero-slider-active-1 {
    position: relative;
}

.dot-style-1 {
    ul {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 12.7%;
        display: flex;
        justify-content: center;
        @media #{$xs-layout} {
            bottom: 7.7%;
        }
        @media #{$sm-layout} {
            bottom: 10.7%;
        }
        li {
            margin: 0 9px;
            button {
                border: none;
                padding: 0;
                font-size: 0px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: #cccccc;
            }
            &.slick-active {
                button {
                    background-color: $theme-color-red;
                }
            }
        }
    }
}

.nav-style-1 {
    > span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7.5%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: $black;
        font-size: 24px;
        background-color: $white;
        cursor: pointer;
        transition: all .3s ease 0s;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        @media #{$xx-layout} {
            left: 1.5%;
        }
        @media #{$xl-layout} {
            left: 1.5%;
        }
        @media #{$lg-layout} {
            left: 1.5%;
        }
        @media #{$md-layout} {
            left: 1.5%;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
        }
        @media #{$xs-layout} {
            left: 1.5%;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 15px;
        }
        &.slider-icon-1-next {
            left: auto; 
            right: 7.5%;
            @media #{$xx-layout} {
                right: 1.5%;
            }
            @media #{$xl-layout} {
                right: 1.5%;
            }
            @media #{$lg-layout} {
                right: 1.5%;
            }
            @media #{$md-layout} {
                right: 1.5%;
            }
            @media #{$xs-layout} {
                right: 1.5%;
            }
        }
        &:hover {
            background-color: $theme-color-red;
            color: $white;
        }
    }
    &:hover {
        span {
            opacity: 1;
            visibility: visible;
            left: 9.5%;
            @media #{$xx-layout} {
                left: 2.5%;
            }
            @media #{$xl-layout} {
                left: 2.5%;
            }
            @media #{$lg-layout} {
                left: 2.5%;
            }
            @media #{$md-layout} {
                left: 2.5%;
            }
            @media #{$xs-layout} {
                left: 2.5%;
            }
            &.slider-icon-1-next {
                right: 9.5%;
                left: auto;
                @media #{$xx-layout} {
                    right: 2.5%;
                }
                @media #{$xl-layout} {
                    right: 2.5%;
                }
                @media #{$lg-layout} {
                    right: 2.5%;
                }
                @media #{$md-layout} {
                    right: 2.5%;
                }
                @media #{$xs-layout} {
                    right: 2.5%;
                }
            }
        }
    }
    &.nav-style-1-modify {
        > span {
            left: 1%;
            &.slider-icon-1-next {
                right: 1%;
                left: auto;
            }
        }
        &:hover {
            span {
                left: 2%;
                &.slider-icon-1-next {
                    right: 2%;
                    left: auto;
                }
            }
        }
    }
    &.nav-style-1-modify-2 {
        > span {
            left: 1%;
            &.slider-icon-1-next {
                right: 1%;
                left: auto;
            }
        }
        &:hover {
            span {
                left: 0%;
                &.slider-icon-1-next {
                    right: 0%;
                    left: auto;
                }
            }
        }
    }
    &.nav-style-1-green {
        > span {
            &:hover {
                background-color: $theme-color-green;
                color: $white;
            }
        }
    }
    &.nav-style-1-blue {
        > span {
            &:hover {
                background-color: $theme-color-blue;
                color: $white;
            }
        }
    }
    &.nav-style-1-green-2 {
        > span {
            &:hover {
                background-color: $theme-color-green-2;
                color: $white;
            }
        }
    }
    &.nav-style-1-orange {
        > span {
            &:hover {
                background-color: $orange;
                color: $white;
            }
        }
    }
}

.single-animation-wrap {
    &.slick-active {
        .slider-animated-1 {
            h4 {
                animation-delay: 1.1s;
                animation-name: fadeInUp;
            }
            h1 {
                animation-delay: 1.4s;
                animation-name: fadeInUp;
            }
            p {
                animation-delay: 1.7s;
                animation-name: fadeInUp;
            }
            h5 {
                animation-delay: 2.0s;
                animation-name: fadeInUp;
            }
            a {
                animation-delay: 2.0s;
                animation-name: fadeInUp;
            }
            .slider-tab-small-img {
                animation-delay: 2.3s;
                animation-name: fadeInUp;
            }
            .slider-tab-big-img-wrap {
                animation-delay: 2.0s;
                animation-name: fadeInUp;
            }
            .btn-style-3 {
                a {
                    animation-delay: 2.5s;
                    animation-name: fadeInUp;
                }
            }
            &.hero-slider-img-1 {
                img {
                    animation-delay: 1.7s;
                    animation-name: fadeInUp;
                }
            }
            &.hm6-hero-slider-img {
                img {
                    animation-delay: 1.7s;
                    animation-name: fadeInUp;
                }
            }
            .hm9-hero-slider-img {
                img {
                    animation-delay: 1.7s;
                    animation-name: fadeInUp;
                }
            }
            .hm10-hero-slider-img {
                img {
                    animation-delay: 1.7s;
                    animation-name: fadeInUp;
                }
            }
            .hero-slider-content-5 , 
            .hero-slider-content-6 { 
                h5 {
                    animation-delay: 1.1s;
                    animation-name: fadeInUp;
                }
            }
            .hm7-hero-slider-img {
                img {
                    animation-delay: 1.7s;
                    animation-name: fadeInUp;
                }
            }
        }
    }
}

.hero-slider-content-2 {
    padding: 0 0 0 170px;
    @media #{$lg-layout} {
        padding: 0 0 0 70px;
    }
    @media #{$md-layout} {
        padding: 0 0 0 30px;
    }
    @media #{$xs-layout} {
        padding: 0 0 0 15px;
    }
    h1 {
        font-size: 48px;
        line-height: 60px;
        font-weight: 300;
        letter-spacing: -.25px;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 40px;
        }
        span {
            color: #628787;
            font-weight: bold;
        }
    }
    p {
        width: 67%;
        margin: 15px 0 65px;
        @media #{$md-layout} {
            margin: 15px 0 35px;
        }
        @media #{$xs-layout} {
            width: 80%;
            color: $black;
            margin: 15px 0 24px;
        }
    }
    &.hero-slider-content-hm5 {
        padding: 0 0 0 0px;
        position: relative;
        z-index: 9;
        h1 {
            @media #{$xs-layout} {
                color: $white;
            }
            span {
                color: $black;
                @media #{$xs-layout} {
                    color: $white;
                }
            }
        }
        p {
            width: 29%;
            @media #{$xs-layout} {
                width: 100%;
                color: $white;
            }
        }
        @media #{$xs-layout} {
            .btn-style-2 {
                a {
                    color: $white;
                    &:hover {
                        color: $theme-color-red;
                    }
                }
            }
        }
    }
}

.btn-style-2 {
    a {
        display: inline-block;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .25px;
        color: $black;
        i {
            margin-left: 10px;
            position: relative;
            top: 1px;
        }
        &:hover {
            color: $theme-color-red;
        }
    }
}

.dot-style-2 {
    &.dot-style-2-position-1 {
        ul {
            left: 170px;
            bottom: 9%;
            @media #{$lg-layout} {
                left: 70px;
            }
            @media #{$md-layout} {
                left: 30px;
            }
        }
    }
    &.dot-style-2-position-2 {
        ul {
            left: 50%;
            transform: translateX(-50%);
            bottom: 9%;
        }
    }
    &.dot-style-2-position-3 {
        ul {
            left: 70px;
            bottom: 60px;
            @media #{$xx-layout} {
                left: 15px;
            }
            @media #{$xl-layout} {
                left: 15px;
            }
            @media #{$lg-layout} {
                left: 20px;
            }
            @media #{$md-layout} {
                left: 20px;
            }
            @media #{$xs-layout} {
                left: 15px;
            }
            @media #{$sm-layout} {
                left: 20px;
            }
        }
    }
    &.dot-style-2-position-4 {
        ul {
            left: 70px;
            bottom: 50px;
            @media #{$lg-layout} {
                left: 15px;
                bottom: 30px;
            }
            @media #{$md-layout} {
                left: 20px;
                bottom: 30px;
            }
            @media #{$xs-layout} {
                left: 15px;
                bottom: 30px;
            }
            @media #{$sm-layout} {
                left: 20px;
            }
        }
    }
    &.dot-style-2-position-5 {
        ul {
            left: 100px;
            bottom: 50px;
            @media #{$lg-layout} {
                left: 70px;
            }
            @media #{$md-layout} {
                left: 20px;
            }
            @media #{$xs-layout} {
                left: 15px;
            }
            @media #{$sm-layout} {
                left: 20px;
            }
        }
    }
    &.dot-style-2-position-static {
        ul {
            position: static;
        }
    }
    &.dot-style-2-left-0 {
        ul {
            justify-content: flex-start;
        }
    }
    &.dot-style-2-mrg-1 {
        ul {
            margin: 27px 0 0;
            @media #{$xs-layout} {
                margin: 5px 0 0;
            }
        }
    }
    &.dot-style-2-mrg-2 {
        ul {
            margin: 17px 0 0;
            @media #{$md-layout} {
                margin: 5px 0 0;
            }
            @media #{$xs-layout} {
                margin: -10px 0 0;
            }
        }
    }
    &.dot-style-2-mrg-3 {
        ul {
            margin: 30px 0 0;
        }
    }
    &.dot-style-2-mrg-4 {
        ul {
            margin: 50px 0 0;
            @media #{$lg-layout} {
                margin: 15px 0 0;
            }
            @media #{$md-layout} {
                margin: 30px 0 0;
            }
            @media #{$xs-layout} {
                margin: 20px 0 0;
            }
        }
    }
    ul {
        position: absolute;
        display: flex;
        justify-content: center;
        @media #{$xs-layout} {
            bottom: 7.7%;
        }
        @media #{$sm-layout} {
            bottom: 10.7%;
        }
        li {
            margin: 0 9px 0 0;
            &:last-child {
                margin: 0 0px 0 0;
            }
            button {
                border: none;
                padding: 0;
                font-size: 0px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: #cccccc;
            }
            &.slick-active {
                button {
                    background-color: $theme-color-red;
                }
            }
        }
    }
    &.dot-style-2-active-black {
        ul {
            li {
                &.slick-active {
                    button {
                        background-color: #666666;
                    }
                }
            }
        }
    }
    &.dot-style-2-active-purple {
        ul {
            li {
                &.slick-active {
                    button {
                        background-color: $theme-color-purple;
                    }
                }
            }
        }
    }
    &.dot-style-2-active-green-2 {
        ul {
            li {
                &.slick-active {
                    button {
                        background-color: $theme-color-green-2;
                    }
                }
            }
        }
    }
}

.slider-area {
    .nav-style-2 {
        > span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%) scale(0);
            left: -6.5%;
            text-align: center;
            color: $black;
            font-size: 36px;
            cursor: pointer;
            transition: all .6s ease 0s;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            @media #{$xl-layout} {
                left: 0%;
            }
            @media #{$lg-layout} {
                left: 0%;
                font-size: 30px;
            }
            @media #{$md-layout} {
                font-size: 25px;
                left: 0%
            }
            @media #{$xs-layout} {
                font-size: 20px;
                left: 0%
            }
            &.slider-icon-1-next {
                left: auto; 
                right: -6.5%;
                @media #{$xl-layout} {
                    right: 0%;
                }
                @media #{$lg-layout} {
                    right: 0%;
                }
                @media #{$md-layout} {
                    right: 0%
                }
                @media #{$xs-layout} {
                    right: 0%
                }
            }
            &:hover {
                color: $theme-color-red;
            }
        }
        &.nav-style-2-modify {
            > span {
                @media #{$xx-layout} {
                    left: -4.5%;
                    &.slider-icon-1-next {
                        left: auto; 
                        right: -4.5%;
                    }
                }
                @media #{$md-layout} {
                    left: 0%;
                    &.slider-icon-1-next {
                        left: auto; 
                        right: 0%;
                    }
                }
                @media #{$xs-layout} {
                    left: 0%;
                    color: $white;
                    &.slider-icon-1-next {
                        left: auto; 
                        right: 0%;
                    }
                }
            }
        }
    }
    &:hover {
        .nav-style-2 {
            > span {
                opacity: 1;
                visibility: visible;
                transform: translateY(-50%) scale(1);
            }
        }
    }
}

.hero-slider-content-3 {
    margin-left: -30px;
    @media #{$lg-layout} {
        margin-left: -20px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        text-align: center;
    }
    @media #{$sm-layout} {
        margin-left: 0px;
        text-align: left;
    }
    h4 {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: $theme-color-green;
        margin: 0;
        letter-spacing: .5px;
    }
    h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 52px;
        margin: 10px 0 24px;
        letter-spacing: -.25px;
        @media #{$lg-layout} {
            font-size: 35px;
            line-height: 40px;
            margin: 10px 0 10px;
        }
        @media #{$md-layout} {
            font-size: 42px;
            line-height: 50px;
            margin: 10px 0 12px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 38px;
            margin: 5px 0 10px;
        }
    }
    p {
        width: 60%;
        margin: 0 0 28px;
        @media #{$lg-layout} {
            width: 80%;
            margin: 0 0 18px;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 0 0 18px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 0 0 16px;
        }
    }
    h5 {
        font-size: 16px;
        color: #666666;
        margin: 0 0 0px;
        letter-spacing: .25px;
        span {
            font-weight: 600;
            color: $black;
            text-transform: uppercase;
        }
    }
    .slider-tab-small-img {
        margin: 20px 0 45px;
        @media #{$lg-layout} {
            margin: 20px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 15px 0 25px;
            justify-content: center;
        }
        @media #{$sm-layout} {
            justify-content: flex-start;
        }
        a {
            display: inline-block;
            border: 1px solid transparent;
            margin: 0 10px 0 0;
            &.active {
                border: 1px solid #cccccc;
            }
            &:last-child {
                margin: 0 0px 0 0;
            }
            img {
                max-width: 100%;
            }
        }
    }
}

.btn-style-3 {
    a {
        display: inline-block;
        font-weight: bold;
        color: $white;
        text-transform: uppercase;
        background-color: $theme-color-green;
        padding: 12px 27px 11px;
        @media #{$xs-layout} {
            padding: 10px 20px 9px;
        }
        i {
            font-size: 14px;
            margin-left: 5px;
        }
        &:hover {
            background-color: $black;
        }
    }
}

.slider-tab-big-img-wrap {
    .slider-tab-big-img {
        @media #{$xs-layout} {
            text-align: center;
            margin-top: 20px;
        }
        @media #{$sm-layout} {
            text-align: left;
            margin-top: 0px;
        }
        img {
            max-width: 100%;
            @media #{$xs-layout} {
                max-width: 130px;
                display: inline-block;
            }
            @media #{$sm-layout} {
                max-width: 100%;
                display: block;
            }
        }
    }
}

.hero-slider-content-4 {
    position: relative;
    z-index: 9;
    h4 {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
        display: block;
        text-transform: uppercase;
    }
    h1 {
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -.25px;
        text-transform: uppercase;
        margin: 24px 0 30px;
        @media #{$xx-layout} {
            font-size: 50px;
            line-height: 60px;
            margin: 14px 0 10px;
        }
        @media #{$xl-layout} {
            font-size: 50px;
            line-height: 60px;
            margin: 14px 0 10px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
            line-height: 60px;
            margin: 14px 0 10px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            line-height: 50px;
            margin: 14px 0 10px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 32px;
            margin: 10px 0 10px;
        }
    }
    p {
        font-size: 16px;
        line-height: 26px;
        margin: 0 0 43px;
        width: 29%;
        @media #{$lg-layout} {
            width: 46%;
            margin: 0 0 23px;
        }
        @media #{$md-layout} {
            width: 37%;
            margin: 0 0 23px;
        }
        @media #{$xs-layout} {
            width: 100%;
            font-size: 15px;
            margin: 0 0 20px;
            color: $black;
        }
    }
}

.hm6-hero-slider-img {
	margin-left: 33px;
    @media #{$lg-layout} {
        margin-left: 20px;
    }
    @media #{$xs-layout} {
        text-align: center;
        margin-left: 20px;
        margin-top: 20px;
        margin-right: 20px;
    }
    @media #{$sm-layout} {
        margin-left: 0px;
        margin-top: 0px;
        margin-right: 0px;
    }
    img {
        width: 100%;
        @media #{$xs-layout} {
            display: inline-block;
        }
    }
}

.hero-slider-content-5 {
    padding-left: 170px;
    @media #{$xx-layout} {
        padding-left: 100px;
    }
    @media #{$xl-layout} {
        padding-left: 100px;
    }
    @media #{$lg-layout} {
        padding-left: 70px;
    }
    @media #{$md-layout} {
        padding-left: 50px;
    }
    @media #{$xs-layout} {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
        margin-bottom: 0;
        padding-left: 30px;
        padding-right: 0px;
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
    h1 {
        font-size: 42px;
        line-height: 48px;
        margin: 18px 0 28px;
        letter-spacing: -.25px;
        @media #{$md-layout} {
            font-size: 30px;
            line-height: 42px;
            margin: 10px 0 18px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 42px;
            margin: 6px 0 9px;
        }
        @media #{$sm-layout} {
            font-size: 27px;
            line-height: 40px;
        }
    }
    p {
        width: 61%;
        @media #{$md-layout} {
            width: 78%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
    }
}

.hm7-hero-slider-img {
    margin: 0 165px 0 0;
    @media #{$xx-layout} {
        margin: 0 100px 0 0;
    }
    @media #{$xl-layout} {
        margin: 0 100px 0 0;
    }
    @media #{$lg-layout} {
        margin: 0 70px 0 0;
    }
    @media #{$md-layout} {
        margin: 0 50px 0 0;
    }
    @media #{$xs-layout} {
        text-align: center;
        margin: 0 15px 0 15px;
    }
    @media #{$sm-layout} {
        text-align: center;
        margin: 0 30px 0 0;
    }
    img {
        width: 100%;
    }
}
.hero-slider-content-6 {
    padding-left: 100px;
    position: relative;
    z-index: 9;
    @media #{$lg-layout} {
        padding-left: 70px;
    }
    @media #{$md-layout} {
        padding-left: 20px;
    }
    @media #{$xs-layout} {
        padding-left: 15px;
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
    h1 {
        font-size: 42px;
        line-height: 48px;
        font-weight: 600;
        letter-spacing: -.25px;
        text-transform: uppercase;
        margin: 15px 0 28px;
        @media #{$lg-layout} {
            font-size: 30px;
            line-height: 40px;
            margin: 15px 0 12px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            line-height: 40px;
            margin: 15px 0 12px;
        }
        @media #{$xs-layout} {
            font-size: 34px;
            line-height: 40px;
            margin: 15px 0 12px;
        }
    }
    p {
        width: 42%;
        margin: 0 0 33px;
        @media #{$lg-layout} {
            width: 46%;
            margin: 0 0 20px;
        }
        @media #{$md-layout} {
            width: 46%;
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            color: $black;
            width: 100%;
            margin: 0 0 15px;
        }
        @media #{$sm-layout} {
            width: 50%;
        }
    }
    &.slider-content-hm9 {
        padding-left: 180px;
        padding-top: 67px;
        @media #{$lg-layout} {
            padding-left: 70px;
            padding-top: 57px;
        }
        @media #{$md-layout} {
            padding-left: 0px;
            padding-top: 40px;
        }
        @media #{$xs-layout} {
            padding-left: 0px;
            padding-top: 50px;
            text-align: center;
        }
        @media #{$sm-layout} {
            text-align: left;
            padding-top: 0px;
        }
        h1 {
            margin: 12px 0 21px;
            @media #{$lg-layout} {
                margin: 12px 0 11px;
                line-height: 35px;
            }
            @media #{$md-layout} {
                margin: 7px 0 6px;
                line-height: 28px;
                font-size: 27px;
            }
            @media #{$xs-layout} {
                margin: 12px 0 10px;
                line-height: 30px;
                font-size: 27px;
            }
        }
        p {
            width: 100%;
            margin: 0 0 37px;
            @media #{$lg-layout} {
                margin: 0 0 17px;
            }
            @media #{$md-layout} {
                margin: 0 0 9px;
            }
            @media #{$xs-layout} {
                margin: 0 0 15px;
            }
        }
        &.slider-content-hm10 {
            padding-left: 150px;
            padding-top: 50px;
            @media #{$lg-layout} {
                padding-left: 70px;
                padding-top: 40px;
            }
            @media #{$md-layout} {
                padding-left: 0px;
                padding-top: 0px;
            }
            @media #{$xs-layout} {
                padding-left: 0px;
                padding-top: 0px;
            }
            @media #{$sm-layout} {
                text-align: center;
                p {
                    width: 67%;
                    margin: 0 auto 15px;
                }
            }
        }
    }
}

.hm9-hero-slider-img {
    margin-left: -40px;
    @media #{$xl-layout} {
        margin-left: -20px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        padding-top: 30px;
        padding-bottom: 50px;
        text-align: center;
    }
    @media #{$sm-layout} {
        padding-bottom: 0px;
    }
    img {
        width: 100%;
        @media #{$xs-layout} {
            display: inline-block;
        }
    }
}
.single-hero-slider-hm10 {
	padding-top: 55px;
}
.hm10-hero-slider-img {
	margin-left: -170px;
	margin-right: 50px;
    @media #{$xx-layout} {
        margin-left: -120px;
	    margin-right: 0px;
    }
    @media #{$xl-layout} {
        margin-left: -120px;
	    margin-right: 0px;
    }
    @media #{$lg-layout} {
        margin-left: -120px;
	    margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-left: -50px;
        margin-right: 0;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        padding-top: 30px;
        text-align: center;
    }
    img {
        width: 100%;
        @media #{$xs-layout} {
            display: inline-block;
        }
    }
}

@media #{$md-layout} {
    .slider-area {
        &.pt-30 {
            padding-top: 0px;
        }
    }
}
@media #{$xs-layout} {
    .hm2-slider-bg {
        background-position: 80%;
    }
    .single-hero-slider-hm3 {
        padding: 50px 0 0;
    }
    .slider-area {
        &.pt-30 {
            padding-top: 0px;
        }
    }
}
@media #{$sm-layout} {
    .hm2-slider-bg {
        background-position: 75%;
    }
    .single-hero-slider-hm3 {
        padding: 0px 0 0;
    }
    .single-hero-slider-hm9 {
        padding: 50px 0 55px;
    }
}

